
<template>
  <div class="page-proxy">
    <div class="wrap">
      <!-- 代理申请 -->
      <div class="title">{{$t("componentsViews.text76")}}</div>
      <Form
        v-if="!resultsShow"
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
      <!-- 账号 -->
        <Form-item :label="$t('componentsViews.text76')+'：'" prop="account">
          <Input
            v-model="form.account"
            :placeholder="$t('componentsViews.text77')"
            maxlength="15"
            @blur="blurHandler('account')"
          ></Input>
        </Form-item>
        <!-- 密码 -->
        <Form-item :label="$t('componentsViews.text37')+'：'" prop="password">
          <Input
            type="password"
            v-model="form.password"
            :placeholder="$t('componentsViews.text78')"
            maxlength="16"
            @blur="blurHandler('password')"
          ></Input>
        </Form-item>
        <!-- 确认密码 -->
        <Form-item :label="$t('componentsViews.text38')+'：'" prop="confirmPass">
          <Input
            type="password"
            v-model="form.confirmPass"
            :placeholder="$t('componentsViews.text106')"
            maxlength="16"
            @blur="blurHandler('confirmPass')"
          ></Input>
        </Form-item>
        <!-- 名字 -->
        <Form-item :label="$t('componentsViews.text80')+'：'" prop="name2">
          <Input
            v-model="form.name2"
            :placeholder="$t('componentsViews.text79')"
            maxlength="8"
          ></Input>
        </Form-item>
        <!-- 手机号 -->
        <Form-item :label="$t('componentsViews.text39')+'：'" prop="phone">
          <Input
            v-model="form.phone"
            :placeholder="$t('componentsViews.text52')"
            maxlength="11"
          ></Input>
        </Form-item>
        <!-- 生日 -->
        <Form-item :label="$t('componentsViews.text82')+'：'" prop="birthday2">
          <Date-picker
            v-model="form.birthday2"
            type="date"
            :placeholder="$t('componentsViews.text83')"
            value-format="yyyy-MM-dd"
          ></Date-picker>
        </Form-item>
        <!-- QQ -->
        <Form-item label="QQ：" prop="qq2">
          <Input
            v-model="form.qq2"
            :placeholder="$t('componentsViews.text84')"
          ></Input>
        </Form-item>
        <!-- 微信 -->
        <Form-item :label="$t('componentsViews.text47')+'：'" prop="wechat2">
          <Input
            v-model="form.wechat2"
            :placeholder="$t('componentsViews.text85')"
          ></Input>
        </Form-item>
        <!-- 性别 -->
        <Form-item :label="$t('componentsViews.text86') +'：'" prop="sex">
          <Radio-group v-model="form.sex">
            <Radio ::label="1">{{$t('componentsViews.text44')}}</Radio>
            <Radio ::label="0">{{$t('componentsViews.text45')}}</Radio>
          </Radio-group>
        </Form-item>
        <!-- 邮箱 -->
        <Form-item :label="$t('componentsViews.text87')+'：'" prop="email2">
          <Input
            v-model="form.email2"
            :placeholder="$t('componentsViews.text107')"
          ></Input>
        </Form-item>
        <Form-item :label="$t('componentsViews.text88')+'：'" prop="address2">
          <Input
            v-model="form.address2"
            :placeholder="$t('componentsViews.text89')"
          ></Input>
        </Form-item>
      </Form>
      <div v-if="resultsShow" class="agent-results">
        <p>{{$t('componentsViews.text90')}}</p>
      </div>
      <div class="submit" v-if="!resultsShow">
        <div  class="agent-submit"  round @click="submitForm('ruleForm')">{{$t('componentsViews.text91')}}</div>
      </div>
      <div v-else class="agent-submit iknow-btn"  round @click="_jump('home')">{{$t('componentsViews.text92')}}</div>
    </div>
  </div>
</template>

<script>
import { Form, FormItem, Input,RadioGroup,Radio,DatePicker,Message } from 'element-ui'
import { _emailIsValid,_jump } from 'core/utils/utils'
import { _appSaveAction } from 'core/comp/pages/agent'
export default {
  components: {
    Form,
    FormItem,
    Input,
    RadioGroup,
    Radio,
    DatePicker
  },
  data() {
    //账户校验
    const checkAccount = (rule, value, callback) => {
      if (!value) {
        // 请输入账号
        return callback(new Error(this.$t('componentsViews.text3')));
      } else {
        if (!/^[a-z0-9]{4,15}$/.test(value)) {
          callback(new Error(this.$t('componentsViews.text93')));
        } else {
          callback();
        }
      }
    };
    //手机号校验
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('componentsViews.text94')));
      } else {
        const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error(this.$t('componentsViews.text95')));
        }
      }
    };
    //密码校验
    const validatePsdReg = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('componentsViews.text57')));
      } else {
        if (!/^[a-z0-9]{6,16}$/.test(value)) {
          callback(new Error(this.$t('componentsViews.text96')));
        } else {
          callback();
        }
      }
    };
    //二次密码确认
    const passwordChecking = (rule, value, callback) => {
      if (!value) {
        // 密码
        return callback(new Error(this.$t('componentsViews.text3')));
      } else {
        console.log(value,this.form.password)
        if (value != this.form.password) {
          // 密码不一致
          callback(new Error(this.$t('componentsViews.text97')));
        } else {
          callback();
        }
      }
    };
    //姓名校验
    const checkName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('componentsViews.text79')));
      } else {
        if (!/^.{0,8}$/.test(value)) {
          callback(new Error(this.$t('componentsViews.text99')));
        } else {
          callback();
        }
      }
    };
    //生日校验
    const checkBirth = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('componentsViews.text100')));
      } else {
        callback();
      }
    };
    //qq校验
    const checkQQ = (rule, value, callback) => {
      const reg = /^[1-9]\d{4,10}$/;
      if (value) {
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error(this.$t('componentsViews.text101')));
        }
      } else {
        callback();
      }
    };
    //微信校验
    const checkWechat = (rule, value, callback) => {
      const reg = /^[a-zA-Z][a-zA-Z\d_-]{5,19}$/;
      if (value) {
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error(this.$t('componentsViews.text102')));
        }
      } else {
        callback();
      }
    };
    //邮箱校验
    const checkEmail = (rule, value, callback) => {
      if (value) {
        if (_emailIsValid(value)) {
          callback();
        } else {
          return callback(new Error(this.$t('componentsViews.text103')));
        }
      } else {
        callback();
      }
    };
    return {
      rules: {
        account: [{ required: true, validator: checkAccount, trigger: "blur" },],
        password: [{ required: true, validator: validatePsdReg, trigger: "blur" },],
        confirmPass: [{ required: true, validator: passwordChecking, trigger: "blur" },],
        name2: [{ required: true, validator: checkName, trigger: "blur" }],
        phone: [{ required: true, validator: checkPhone, trigger: "blur" }],
        birthday2: [{ required: true, validator: checkBirth, trigger: "blur" }],
        qq2: [{ required: false, validator: checkQQ, trigger: "blur" }],
        wechat2: [{ required: false, validator: checkWechat, trigger: "blur" }],
        email2: [{ required: false, validator: checkEmail, trigger: "blur" }],
      },
      form: {
        account: "", //用户名
        password: "", //密码
        confirmPass: "", //确认密码
        name2: "", //姓名
        phone: "", //号码
        birthday2: "", //生日
        qq2: "",
        wechat2: "",
        sex: 1,
        email2: "",
        address2: ""
      },
      resultsShow: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
      _jump,
    //提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            phone: this.form.phone,
            password: this.form.password,
            confirmPass: this.confirmPass,
            name: this.form.account, //账户名
            nickname: this.form.name2 || "", //姓名
            birthday: this.form.birthday2 || "", //生日
            qq: this.form.qq2 || "",
            wechat: this.form.wechat2 || "",
            sex: this.form.sex || "", //性别
            email: this.form.email2 || "", //邮箱
            address: this.form.address2 || "" //地址
          };
          _appSaveAction(data).then(res => {
            if (res) {
              this.resultsShow = true
              Message.success(this.$t('componentsViews.text104'))
              // todo 重置表单
            }
          })
        } else {
          Message.error(this.$t('componentsViews.text105'));
        }
      });
    },
    //大写转小写
    blurHandler(name) {
      this.form[name] = this.form[name].toLowerCase();
      //全角转半角
      this.form[name] = this.toCDB(this.form[name]);
    },
    //全角转为半角
    toCDB(str) {
      var tmp = "";
      for (var i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) == 12288) {
          tmp += String.fromCharCode(str.charCodeAt(i) - 12256);
        }
        if (str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375) {
          tmp += String.fromCharCode(str.charCodeAt(i) - 65248);
        } else {
          tmp += String.fromCharCode(str.charCodeAt(i));
        }
      }
      return tmp;
    },
    toHome() {
      this.$router.push({ path: "/home" });
    }
  },
};
</script>

<style lang="less" scoped>
.page-proxy {
  .wrap {
    width: 700px;
    padding: 28px 0;
    margin: 0 auto;
    .title {
        font-weight:700;
        color:#fdf86a;
        font-size:18px;
        text-align: center;
        margin-bottom: 26px;
    }
    .Form {
      .Form-item {
        margin-bottom: 10px;
        label {
            font-weight: 700;
            color: #555;
        }
        .Date-editor.Input, .Date-editor.Input__inner {
          width: 100%;
        }
      }
    }
    .agent-results {
      padding-bottom: 40px;
      color:#fdf86a;
      font-size: 14px;
      text-align: center;
    }
    .submit {
      padding-left: 100px;
      
    }
    .agent-submit {
        width:160px;
        height:54px;
        line-height: 54px;
        font-weight:700;
        text-align: center;
        color:#000000;
        font-size:18px;
        background: url('../../../assets/images/other/black_a001_list_buttonbg_i.png') no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
    }
    .iknow-btn {
        margin: 0 auto;
    }
  }
}
</style>
