<!-- 规则与声明 -->
<template>
    <div class="declarations">
        <div class="titleBOx">
               <h2 class="title">
                {{$t('declarations.text88')}}
            </h2>
        </div>
        <div class="content"> 
            <p class="tip2">{{$t('declarations.text89')}}</p>
            <h3 class="tip1">{{$t('declarations.text90')}}</h3>
            <p class="tip2">{{$t('declarations.text91')}}</p>
            <p class="tip2">{{$t('declarations.text92')}}</p>
            <p class="tip2">{{$t('declarations.text93')}}</p>

            <h3 class="tip1">{{$t('declarations.text94')}}</h3>

            <p class="tip2">{{$t('declarations.text95')}}</p>
            
            <h3 class="tip1">{{$t('declarations.text96')}}</h3>

            <p class="tip2">{{$t('declarations.text97')}}</p>

            <p class="tip2">{{$t('declarations.text98')}}</p>

            <p class="tip2">{{$t('declarations.text99')}}</p>

            <p class="tip2">{{$t('declarations.text100')}}</p>

            <p class="tip2">{{$t('declarations.name1')}} </p>

            <h3 class="tip1">{{$t('declarations.name2')}}</h3>
            <p class="tip2">{{$t('declarations.name3')}}</p>
            <p class="tip2">{{$t('declarations.name4')}}</p>
            <p class="tip2">{{$t('declarations.name5')}}</p>
            <p class="tip2">{{$t('declarations.name6')}}</p>
            <p class="tip2">{{$t('declarations.name7')}}</p>
            <p class="tip2">{{$t('declarations.name8')}}</p>

            <h3 class="tip1">{{$t('declarations.name9')}}</h3>
            <p class="tip2">{{$t('declarations.name10')}}</p>
            <p class="tip2">{{$t('declarations.name11')}}</p>
            <h3 class="tip1">{{$t('declarations.name12')}}</h3>
            <p class="tip2">{{$t('declarations.name13')}}</p>
            <p class="tip2">{{$t('declarations.name14')}}</p>
            <p class="tip2">{{$t('declarations.name15')}}</p>

        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
        }
    },
    computed: {
    },
    methods:{
    },
    mounted(){

    }
}
</script>
<style scoped lang='less'>
    .declarations{
        .titleBOx{
            .title{
                font-weight:700;
                color:#fdf86a;
                font-size:16px;
            }
        }
        .content{
            .tip1{
                padding: 10px 0px 20px;
                color: #fff;
                font-size: 16px;
                font-weight: 500;
            }
            .tip2{
                line-height: 32px;
                font-size: 14px;
                color: #ccc;
                .tip3{
                    color: #ec2828;
                }
            }
        }
    }
</style>