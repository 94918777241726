
<template>
    <div class="page-help">
        <div class="page-help-box">
            <div class="help-left">
                <div v-for="(item,index) in list"  :key="index" :class="{'item-act':curIndex === index}"
                @click="switchTab(index)">
                    <img :src="curIndex === index ? item.iconAct : item.icon" alt="">
                    {{ item.title }}
                </div>
            </div>
            <div class="help-right">
                <About v-show="curIndex === 0" />
                <DepositDemo v-if="curIndex === 1" />
                <WithdrawDemo v-if="curIndex === 2" />
                <Declarations v-show="curIndex === 3" />
                <CommonProblem v-show="curIndex === 4" />
                <AgentDetail  v-show="curIndex === 5" />
                <AgentApply  v-show="curIndex === 7" />
            </div>
        </div>
    </div>
</template>
<script>
import About from 'core/pages/help/About.vue'
import DepositDemo from 'core/pages/help/DepositDemo.vue'
import WithdrawDemo from 'core/pages/help/WithdrawDemo.vue'
import Declarations from 'core/pages/help/Declarations.vue'
import CommonProblem from 'core/pages/help/CommonProblem.vue'
import AgentApply from 'core/pages/AgentApply/AgentApply.vue'
import AgentDetail from 'core/pages/AgentDetail/AgentDetail.vue'
export default {
    components: {
        About,
        DepositDemo,
        WithdrawDemo,
        Declarations,
        CommonProblem,
        AgentApply,
        AgentDetail
    },
    watch: {
        $route: {
            handler: function (val) {
                if(val.query.id) {
                    this.curIndex = Number(val.query.id)
                } else {
                   this.curIndex = 0 
                }
            },
            immediate: true
        }
    },
    data () {
        return {
            list:[
                { title: '关于我们',icon:require('../../assets/images/other/black_xsjc_a001_icon02.png'),iconAct:require('../../assets/images/other/black_xsjc_a001_icon02_i.png') },
                { title: '存款帮助',icon:require('../../assets/images/other/black_xsjc_a001_icon03.png'),iconAct:require('../../assets/images/other/black_xsjc_a001_icon03_i.png') },
                { title: '取款帮助',icon:require('../../assets/images/other/black_xsjc_a001_icon04.png'),iconAct:require('../../assets/images/other/black_xsjc_a001_icon04_i.png') },
                { title: '规则与声明',icon:require('../../assets/images/other/black_xsjc_a001_icon05.png'),iconAct:require('../../assets/images/other/black_xsjc_a001_icon05_i.png') },
                { title: '常见问题',icon:require('../../assets/images/other/black_xsjc_a001_icon06.png'),iconAct:require('../../assets/images/other/black_xsjc_a001_icon06_i.png') },
                { title: '代理加盟',icon:require('../../assets/images/other/black_xsjc_a001_icon07.png'),iconAct:require('../../assets/images/other/black_xsjc_a001_icon07_i.png') },
                { title: '代理登录',icon:require('../../assets/images/other/black_xsjc_a001_icon08.png'),iconAct:require('../../assets/images/other/black_xsjc_a001_icon08_i.png') },
                { title: '代理注册',icon:require('../../assets/images/other/black_xsjc_a001_icon09.png'),iconAct:require('../../assets/images/other/black_xsjc_a001_icon09_i.png') }
            ],
            curIndex:0
        }
    },

    mounted() {
        
        
    },
    methods: {
        switchTab(index) {
            if(index === 6)  return window.open('https://agent01.bets8888.net','_blank')
            this.curIndex = index
        }
    }
}
</script>

<style lang="less" scoped>

@import url("../../assets/css/page-help.less");
</style>