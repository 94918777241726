<!-- 存款演示 -->
<template>
    <div class="withdraw-demo">
        <div class="titleBOx">
            <!-- 会员取款 -->
               <h2 class="title">
                {{$t('withdrawDemo.text1')}}
            </h2>
        </div>
        <div class="content"> 
            <div class="box">
                <h5 class="tip4">{{$t('withdrawDemo.text2')}}</h5>
                <p class="tip2">{{$t('withdrawDemo.text3')}}</p>
                <p class="tip2 tip5">{{$t('withdrawDemo.text4')}}</p>
                <img loading="lazy" v-lazy="require('@/assets/images/text/withdraw1.jpg')" alt="">
                <p class="tip2">{{$t('withdrawDemo.text5')}}</p>
                <p class="tip2">{{$t('withdrawDemo.text6')}}</p>
                <p class="tip2">{{$t('withdrawDemo.text7')}}</p>
                <p class="tip2">{{$t('withdrawDemo.text8')}}</p>
                <p class="tip2">{{$t('withdrawDemo.text9')}}</p>
                <p class="tip2 tip5">{{$t('withdrawDemo.text1')}}</p>
                <img loading="lazy" v-lazy="require('@/assets/images/text/withdraw2.jpg')" alt="">
                <h5 class="tip4">{{$t('withdrawDemo.text10')}}</h5>
                <img loading="lazy" v-lazy="require('@/assets/images/text/withdraw3.jpg')" alt="">
                <p class="tip2">{{$t('withdrawDemo.text12')}}</p>
                <p class="tip2">{{$t('withdrawDemo.text13')}}</p>
                <p class="tip2">{{$t('withdrawDemo.text14')}}</p>
                <p class="tip2">{{$t('withdrawDemo.text15')}}</p>
                <p class="tip2">{{$t('withdrawDemo.text16')}}</p>
                <p class="tip2">{{$t('withdrawDemo.text17')}}</p>
                <p class="tip2">{{$t('withdrawDemo.text18')}}</p>
                <p class="tip2 tip5">{{$t('withdrawDemo.text19')}}</p>
                <img loading="lazy" v-lazy="require('@/assets/images/text/withdraw4.jpg')" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            index:0
        }
    },
    methods:{
    },
    mounted(){

    }
}
</script>
<style scoped lang='less'>
    .withdraw-demo{
        .titleBOx{
            .title{
                font-weight:700;
                color:#fdf86a;
                font-size:16px;
            }
        }
        .content{
            .box{
                width: 100%;
                .list{
                    display: inline-block;
                    width: 116px;
                    height: 42px;
                    background: #d9d9d9;
                    color: #000;
                    line-height: 45px;
                    font-size: 14px;
                    overflow: hidden;
                    margin-bottom: 30px;
                    margin-top: 10px;
                    margin-right: 10px;
                    font-weight: 600;
                    cursor: pointer;
                    text-align: center;
                }
                .activity{
                    background: #000;
                    color: #fff;
                }
                img{
                    width: 100%;
                    margin-bottom: 10px;
                }
                .tip4 {
                    color: #fff;
                    font-size: 16px;
                    padding: 0 0 10px;
                }
                .tip2 {
                    line-height: 28px;
                    font-size: 14px;
                    color: #ccc;
                }
                .tip5 {
                    margin-bottom: 20px;
                }
            }
        }
    }
</style>